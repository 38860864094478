<template>
    <div style="text-align: left;">
        <el-card shadow="never" style="margin-bottom: 25px;" v-if="1">
            <div slot="header" class="my-header">
                <div class="bold">分类管理</div>
                <div>
                    <el-button type="default" size="mini" @click="addAttr">添加分类</el-button>
                    <el-button type="default" size="mini">全部删除</el-button>
                    <!-- <el-button icon="el-icon-delete" type="danger" size="mini">全部删除</el-button> -->
                    <!-- <el-button size="mini">收起<i class="el-icon-caret-top el-icon--right"></i></el-button> -->
                    <el-button size="mini" @click="hasList=!hasList">{{hasList?"收起":"展开"}}<i class="el-icon-caret-top el-icon--right" :class="[hasList?'el-icon-caret-top':'el-icon-caret-bottom']"></i></el-button>
                </div>
            </div>
            <div v-show="hasList">
                <template v-for="(v,i) of list">
                    <el-button-group class="m-l-15">
                      <el-button type="" size="mini">{{v.name}}</el-button>
                      <el-button type="" size="mini" icon="el-icon-edit-outline"></el-button>
                      <el-button type="" size="mini" icon="el-icon-delete"></el-button>
                    </el-button-group>
                </template>

<!--                     <el-table :data="list" style="width: 100%">
                        <el-table-column type="index" label="编号">
                        </el-table-column>
                        <el-table-column prop="name" label="分类名称">
                        </el-table-column>
                        <el-table-column label="操作" align="right">
                            <template slot-scope="scope">
                                <el-button size="mini" @click="editClassify(scope.row)">修改</el-button>
                                <el-button size="mini" @click="delMessageBox('deleteClassify',scope.row)" type="danger" plain>删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table> -->
            </div>
        </el-card>
        <el-card shadow="never" style="margin-bottom: 25px;text-align: center;" v-else>
            <div>
                <img src="@/assets/images/photo/class_not.png">
                <div class="f14 c-2 m-b-20">
                    当前您还没有相册分类哦！
                </div>
                <el-button type="default" size="mini" @click="addAttr">立即添加</el-button>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-bottom: 25px;">
            <div slot="header" class="my-header">
                <div class="bold">相册</div>
                <div>
                    <el-select v-model="value" placeholder="请选择" size="mini" class="m-r-10">
                      <el-option
                        v-for="item in list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                    <el-button type="default" size="mini" @click="classifyDialogFormVisible=true">创建相册</el-button>
                    <!-- <el-button type="default" size="mini">全部删除</el-button> -->
                    <!-- <el-button icon="el-icon-delete" type="danger" size="mini">全部删除</el-button> -->
                    <!-- <el-button size="mini">收起<i class="el-icon-caret-top el-icon--right"></i></el-button> -->
                    <el-button size="mini" @click="hasList=!hasList">{{hasList?"收起":"展开"}}<i class="el-icon-caret-top el-icon--right" :class="[hasList?'el-icon-caret-top':'el-icon-caret-bottom']"></i></el-button>
                </div>
            </div>
            <div v-show="hasList">
<!--                 <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane v-for="(v,i) of list" :label="v.name" :name="v.id"></el-tab-pane>
                </el-tabs> -->
                <div class="items" v-if="0">
                    <div v-for="(v,i) of 10" class="item m-b-35" :key="i" @click="$oucy.go('/enter/photoDetail')">
                        <img src="@/assets/images/fur1.png">
                        <div class="m-t-15">
                            <div class="f16 c-3 bold">沙发相册1</div>
                            <div class="m-t-15">
                                <el-button size="mini" type="primary">上传照片</el-button>
                                <el-button size="mini">删除相册</el-button>
                            </div>
                            <div class="f14 c-6 m-t-15 p-b-20"><i class="el-icon-picture-outline"></i> 照片数量 35</div>
                        </div>
                    </div>
                </div>
                <div v-else class="text-center">
                    <img src="@/assets/images/photo/class_not.png">
                    <div class="f14 c-2 m-b-20">
                        当前您还没有相册哦！
                    </div>
                    <el-button type="default" size="mini" @click="classifyDialogFormVisible=true">立即创建</el-button>
                </div>
            </div>
        </el-card>
        <el-dialog width="500px" title="创建相册" :visible.sync="classifyDialogFormVisible">
            <el-form :model="classifyForm" ref="classifyForm" :rules="classifyFormRule" label-width="120px">
                <el-form-item label="分类" prop="category">
                    <el-select v-model="classify" placeholder="请选择分类" style="width: 340px">
                        <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="name" autocomplete="off" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitClassifyForm">保 存</el-button>
                    <el-button @click="classifyDialogFormVisible = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import {enterprisePhotoAlbum,enterprisePhotoAlbumItem} from '@/service'

export default {
    name: "Photo",
    data() {
        return {
            // 下面是验证的
            classifyFormRule: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                classify: [
                    { required: true, message: '请选择分类', trigger: 'blur' },
                ]
            },
            hasList: true,
            classifyDialogFormVisible: !true,
            list: [{ name: '家具分类1', id: '1' }, { name: '家具分类2', id: '2' }],
            activeName: '1',
            classifyForm: '',
            classify: '',
            name: '',
        }
    },
    mounted() {},
    methods: {
        handleClick() {},
        submitClassifyForm() {},
        addAttr: function() {
            const me = this;
            me.$prompt('请输入分类名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '分类名称不正确'
            }).then(({ value }) => {
                enterprisePhotoAlbum.addEnterprisePhotoAlbum({}).then(res=>{
                    
                })
                /*                me.goodForm.attrs.push({
                                    key:value,
                                    values:[]
                                });*/
                // me.getSpanArr();
            });
        },
    }
}
</script>
<style scoped>
.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.items {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    align-content: flex-start;
    /*padding: 10px;*/
}

.item {
    /*flex: 1;*/
    max-width: 25%;
    margin: 10px;
    text-align: center;
    background: #F6F6F6;
    border: 1px solid #EAEAEA;
    cursor: pointer;
}
</style>